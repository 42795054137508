import React from 'react';
import styled, { keyframes } from 'styled-components';
import { AntWrapper } from '../components/ant-wrapper';
import {
  Row,
  Col,
  message,
} from 'antd';
import { BeautifulButton, SimpleInput } from '../styles/styled';
import { callApi, config } from '../../config';
import EditorService, { authTokenKey } from '../services/editor.service';
import { navigate } from 'gatsby';
import StateService from '../services/state.service';
import splashBackground from '../images/assets-splash.jpg';

const { demoAccounts } = config;

const AppearAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40%) translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(-50%) translateX(-50%);
  }
`;

const LoginWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url(${splashBackground});
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, .25);
  backdrop-filter: blur(5px) saturate(90%);
  color: #eee;
  text-align: center;
  font-size: 10px;

  a {
    color: #eee;
    text-decoration: none;
    transition: all .1s ease;

    &:hover {
      color: #ff7dc2;
    }
  }
`;

const LoginDialog = styled.div`
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  top: 50%;
  left: 25%;
  width: 350px;
  min-height: 300px;
  transform: translateY(-50%) translateX(-50%);
  background-color: #fff;
  border: solid 1px #000;
  border-radius: 2px;
  box-sizing: border-box;
  z-index: 2;
  box-shadow: -48px 48px 80px rgba(0, 0, 0, .5),
    48px 48px 80px rgba(0, 0, 0, .5);
  opacity: 0;
  animation: ${AppearAnimation} 1s ease 1s both;

  .title {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 20px;
    padding-top: 16px;
    padding-bottom: 8px;
    font-size: 32px;
    font-weight: 600;
    background-color: #fff;
    z-index: 1;
  }

  .body {
    flex-grow: 2;
    flex-shrink: 0;
    padding: 0 20px;
    padding-bottom: 16px;
    color: #000;
    background-color: #fff;
    z-index: 1;
  }

  .footer {
    font-size: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 16px 20px;
    background-color: #263346;
    color: #fff;
    z-index: 1;

    a {
      text-decoration: underline;
    }

    &:not(:last-child) {
      border-bottom: solid 1px #495c75;
    }
  }
`;

const IntroWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: #fff;
  z-index: 1;
  user-select: none;
  cursor: default;
  text-align: right;
  
  & > * {
    text-align: right;
  }

  h1 {
    display: inline-block;
    font-size: 42px;
    line-height: 60px;
    font-weight: bold;
    background-color: #000;
    padding: 20px;
    margin: 5px 0;
    border-radius: 20px 0px 0px 20px;
  }

  h2 {
    display: inline-block;
    font-size: 42px;
    line-height: 60px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    padding: 20px;
    margin: 5px 0;
    border-radius: 20px 0 0 0;
  }

  a {
    display: inline-block;
    font-size: 18px;
    line-height: 38px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background-color: #000;
    padding: 20px;
    margin: 5px 0;
    border-radius: 0 0 0 20px;
    transition: all .02s ease;

    &:hover {
      color: #000;
      background-color: #ff7dc2;
    }
  }
`;

export default class Login extends React.Component {
  enableAccount = null;

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isLogging: false
    };

    StateService.clearAll();
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }

    const querystring = window.location.search.split(/[?&]/gm);
    const queryValues = querystring.map(t => {
      const [ key, value ] = decodeURIComponent(t).split('=');

      if (key === 'a') {
        this.enableAccount = value;
        this.setState({ email: value });
      }
    });

    if (this.enableAccount && demoAccounts.includes(this.enableAccount)) {
      setTimeout(() => {
        this.handleLogin();
      }, 1000);
    }
  }

  handleLogin() {
    if (typeof window === 'undefined') {
      return null;
    }

    const { email, password } = this.state;
    const isDemo = demoAccounts.includes(email);

    if (!email || (!isDemo && !password)) {
      return message.info('Invalid email or password');
    }

    this.setState({
      ...this.state,
      isLogging: true
    });

    EditorService.rememberDemo(false);

    EditorService.login(email, password)
    .then(async () => {
      message.success('Logged in.');
      message.info('Loading project...');

      if (isDemo) {
        EditorService.rememberDemo(true);
      }

      await StateService.fetchProject();

      navigate('/');
    })
    .catch((e) => {
      message.error('Invalid email or password. Try again.');

      this.setState({
        isLogging: false
      });
    });
  }

  loginBoxes = [];

  render() {
    const { isLogging } = this.state;

    return (
      <AntWrapper style={{ minWidth: 1200 }}>
        <LoginWrapper>
          <IntroWrapper>
            <Row>
              <Col span={24}>
                <h2>TreasureChest</h2>
              </Col>
              <Col span={24}>
                <h1>Keep Your Public Stuff Online</h1>
              </Col>
            </Row>
          </IntroWrapper>
          <LoginDialog>
            <div className="title">
              Login
            </div>
            <div className="body">
              <Row>
                <Col span={24}>
                  <SimpleInput>
                    <span>
                      email
                    </span>
                    <input
                      name="user"
                      type="email"
                      defaultValue={this.state.email}
                      onChange={(e) => this.setState({
                        email: e.target.value
                      })}
                    />
                  </SimpleInput>
                </Col>
                <Col span={24}>
                  <SimpleInput>
                    <span>
                      password
                    </span>
                    <input  
                      disabled={demoAccounts.includes(this.state.email)}
                      name="password"
                      type="password"
                      defaultValue={this.state.password}
                      onChange={(e) => this.setState({
                        password: e.target.value
                      })}
                    />
                  </SimpleInput>
                </Col>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <BeautifulButton
                    style={{ minWidth: '100px' }}
                    onClick={() => this.handleLogin()}
                  >
                    {
                      !isLogging ?
                      <>
                        Log In
                      </> :
                      <>
                        <i className="fas fa-spinner-third fa-spin"></i>
                      </>
                    }
                  </BeautifulButton>
                </Col>
              </Row>
            </div>
            <div className="footer">
              No account? <a href="https://access.wtlstudio.nl/treasure-chest">Create one here.</a>
            </div>
            <div className="footer">
              Can't access account? <a href="mailto:support@wtlstudio.com">Contact us.</a>
            </div>
          </LoginDialog>
          <Footer>
            <Row>
              <Col span={24}>
                © 2016-2021 <a href="https://wtlstudio.com" target="_blank">WTL Studio Amsterdam</a>
              </Col>
              <Col span={24}>
                Mar 2021
              </Col>
            </Row>
          </Footer>
        </LoginWrapper>
      </AntWrapper>
    )
  }
};